import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"

import SingleColumnBlock from "@components/singleColumnBlock"

import {
  RequestMoreInfo,
} from "@components/Forms"

const NotFoundPage = () => (
  <PageLayout>
    <SEO title="404 | Requested Page Not Found | Websuasion" />
    <SingleColumnBlock
      textPadded
      text={
        <div>
          <h1>We Couldn't Find That Page</h1>
          <p>The page you were looking for is not available. Try the <a href="/">homepage</a>, or our <a href="/content-marketing-strategy">Data</a>, <a href="/marketing-technology">Tech</a>, or <a href="/fractional-marketing-team">Team</a> pages.</p>
          <h4>Schedule a meeting.</h4>
          <RequestMoreInfo />
        </div>
      }
    />
  </PageLayout>
)

export default NotFoundPage
